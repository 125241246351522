@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes slideInDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-slideInDown {
  animation: slideInDown 0.5s ease-out forwards;
}
